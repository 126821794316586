<template>
    <div class="flex items-end space-x-4 py-1 max-w-[67%]" :class="{'self-end': mine}">
        <div v-if="!mine && group" class="w-8 h-8 shrink-0 bg-gray-200 rounded-full flex items-center text-center bg-center bg-cover" :style="`background-image: url(${avatar})`">
            <i v-if="!avatar" class="fas fa-user" />
        </div>
        <div v-for="a in message.attachments" :key="a">
            <img :src="a" class="w-full rounded-lg" />
            <!-- <video v-else-if="a.type == 'video'" :src="a.url" class="w-32 rounded-lg" />
            <a v-else :href="a.url" class="underline">{{ a.name }}</a> -->
        </div>
        <div v-if="message.content" class="flex flex-col px-3 py-2 rounded-2xl" :class="emoji ? '' : mine ? 'bg-blue-500 text-white' : 'bg-gray-300'">
            <p class="" :class="emoji === 1 ? 'text-5xl' : emoji ? 'text-4xl tracking-[0.5rem]' : 'text-sm'">{{ message.content }}</p>
        </div>
    </div>
</template>

<script>
import emojiRegex from 'emoji-regex';

export default {
    props: ['message','thread'],
    inject: ['store'],
    computed: {
        author() {
            return this.message.author?.id
        },
        avatar() {
            return this.message.author?.image?.url
        },
        mine() {
            return this.author == this.store.user.id
        },
        group() {
            return this.thread?.members?.length > 2
        },
        emoji() {
        const content = this.message.content.trim();
        const regex = emojiRegex();
        const matches = content.match(regex) || [];

        // Remove all matched emojis from content
        const nonEmojiContent = content.replace(regex, '').trim();

        // Check if there's any non-emoji content
        const isOnlyEmojis = nonEmojiContent.length === 0;
        if (!isOnlyEmojis) return false;

        // Check if there are 3 or fewer emojis
        const hasThreeOrFewer = matches.length <= 3;

        return hasThreeOrFewer ? matches.length : false;
        },
    }
}
</script>

<style>

</style>
