<template>
    <div class="flex items-center justify-center w-full">
        <div class="max-w-4xl pt-16 h-[calc(100vh-5rem-2px)] overflow-scroll w-full flex flex-col">
            <h1 class="text-center text-2xl mb-8">Messages</h1>

            <Chat />

        </div>
    </div>
</template>

<script>
import Chat from '../../../../config/plugins/chat/front/chat.vue';

export default {
    components: { Chat },
}
</script>

<style>

</style>
