<template>
    <div class="flex justify-between items-center bg-red-500/20">

        <div v-if="displaySyncing" class="w-full px-4 py-2 flex justify-between relative font-mono">
            <div>Syncing:</div>
            <div>{{ currentEntryIndex+1 <= initialEntries.length ? currentEntryIndex+1 : initialEntries.length }}/{{ initialEntries.length || 1 }}</div>

            <div class="h-1 bg-red-500 absolute -bottom-1 left-0" :style="`width: ${progress}%;`" />
        </div>

        <!-- <div v-if="false || offlineMode" class="p-2">
            <div class="flex space-x-2 items-center">
                <div class="text-2xs">Offline&nbsp;Mode</div>
                <Toggle v-model="offlineMode" :small="true" background="bg-gray-100 dark:bg-gray-700" />
            </div>
        </div> -->

    </div>
</template>

<script>
import Toggle from '../partials/toggle.vue'
export default {
	components: { Toggle },
    inject: ['store'],
    data() {
        return {
            initialEntries: [],
            remainingEntries: [],
            displaySyncing: false,
            processedEntries: 0,
            online: navigator.onLine,
            syncing: false,
            offlineMode: false,
            onlineInterval: null,
        }
    },
    watch: {
        online: {
            async handler() {
                if (!this.syncing) this.sync()
            },
            deep: true,
            immediate: true
        },
        remainingEntries: {
            async handler(n) {
                if (!this.syncing) this.sync()
            },
            deep: true,
            immediate: true
        },
        progress() {
            if (this.progress === 100) {
                this.$emit('synced')
                this.initialEntries = []
                this.remainingEntries = []
            }
        }
    },
    mounted() {
        this.onlineInterval = setInterval(() => {
            this.online = navigator.onLine && !window?.offlineMode
        }, 500);
    },
    beforeDestroy() {
        clearInterval(this.onlineInterval)
    },
    async created() {

        // Give IndexedDB a chance to init...
        setTimeout(async () => {
            this.displaySyncing = (await this.store.localDB.getEntries())?.length
            this.remainingEntries = (await this.store.localDB.getEntries())?.filter(e => (new Date() - new Date(e.updatedLocally)) > 15*1000)
            // this.initialEntries = await this.store.localDB.getEntries()
            // console.log('this.initialEntries', this.initialEntries)
            // console.log('this.initialEntries', new Date() - new Date(this.initialEntries[0].created))
            this.remainingEntries = this.initialEntries
        }, 1000);

        setInterval(async () => {
            this.displaySyncing = (await this.store.localDB.getEntries())?.length
            this.online = navigator.onLine && !window?.offlineMode
            // if (!this.online) return
            this.remainingEntries = (await this.store.localDB.getEntries())?.filter(e => (new Date() - new Date(e.updatedLocally)) > 15*1000)
            this.initialEntries = [...this.initialEntries, ...this.remainingEntries.filter(entry => !this.initialEntries.find(e => e.id === entry.id))]
        }, 15*1000);

    },
    computed: {
        currentEntryIndex() {
            return (this.initialEntries.length - this.remainingEntries.length)
        },
        progress() {
            return Math.round(this.currentEntryIndex / this.initialEntries.length-1 * 100)
        }
    },
    methods: {
        /*
        Doing this instead of a for loop beacuse I don't want things
        to get double saved (multiple instances of the loop running)
        */
        async sync() {
            console.log('syncing', this.remainingEntries?.length, this.online)

            if (!this.remainingEntries?.length) return
            if (!this.online) return

            let entry = this.remainingEntries[0]

            // Don't sync what we're actively working on
            if (this.$route.params.id == entry.id) {
                // wait 10 seconds and try again
                await new Promise(resolve => setTimeout(resolve, 10*1000))
                return this.sync()
            }

            this.syncing = true

            try {
                await this.store.localDB.save(entry, this.syncing)
            } catch(e) {
                console.log('Error saving entry', e, entry)
            }
            this.remainingEntries = (await this.store.localDB.getEntries())?.filter(e => (new Date() - new Date(e.updatedLocally)) > 30*1000)

            // Wait one second
            await new Promise(resolve => setTimeout(resolve, 10*1000))

            if (this.remainingEntries.length) this.sync()
            else this.syncing = false

        }
    }
}
</script>

<style>

</style>
