<template>
    <div class="w-full relative">

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <Modal v-if="selectedOpportunityId" @hide="selectedOpportunityId = null" maxWidth="max-w-2xl">
            <Spinner v-if="!selectedOpportunity" :small="true" class="w-8 h-8 mx-auto" />
            <div v-else class="relative" :class="{'pointer-events-none opacity-50': selectedOpportunity.status == 'Lockout'}">
                <div class="border-b pb-4 mb-8 flex justify-between">
                    <div class="text-xl font-semibold">{{ selectedOpportunity.title }}</div>
                    <div class="ml-2">
                        <div
                            class="text-2xs px-1 py-[2px] bg-gray-100 rounded whitespace-nowrap"
                            :class="{
                                'border border-red-400/50 text-red-400 bg-red-50': ['closed','Lockout'].includes(selectedOpportunity.status),
                                'border border-green-500/50 text-green-500 bg-green-50': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(selectedOpportunity.status),
                                'border border-yellow-400/50 text-yellow-500 bg-yellow-50': ['pending','Soft Set'].includes(selectedOpportunity.status),
                                'border border-orange-400/50 text-orange-400 bg-orange-50': selectedOpportunity.status == 'hidden',
                                'border border-orange-400/50 text-orange-400 bg-orange-50': selectedOpportunity.status == 'Retail',
                                'border border-black/20 text-black bg-black/10': ['Skip','No Answer','Not Interested'].includes(selectedOpportunity.status),
                            }"
                        >
                            {{ selectedOpportunity.status || 'Skip' }}
                        </div>
                    </div>
                </div>
                <div class="flex md:space-x-4 w-full justify-between flex-col-reverse md:flex-row">
                    <div class="space-y-4 flex-grow text-sm mt-4 md:mt-0">

                        <div class="flex space-x-2 items-center">
                            <div v-if="selectedOpportunity.jnid">
                                <a
                                    :href="`https://app.jobnimbus.com/contact/${selectedOpportunity.jnid}`"
                                    target="_blank" class="bg-[#314f8f] rounded text-white px-3 py-2"
                                >
                                    View in JobNimbus
                                    <!-- <img src="https://webappui.jobnimbus.com/images/favicon.png?" class="w-6 block" /> -->
                                </a>
                            </div>

                            <div v-if="!store.production" class="">
                                <a :href="`/opportunities/${selectedOpportunity.id}/estimate`" target="_blank" class="bg-red-500 rounded text-white px-3 py-2">Create Estimate</a>
                            </div>
                        </div>

                        <div class="flex flex-wrap justify-between">
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Knock</div>
                                <Toggle @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ktwc.knock" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Talk</div>
                                <Toggle @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ktwc.talk" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Walk</div>
                                <Toggle @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ktwc.walk" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                            <div class="">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Ctgy</div>
                                <Toggle @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ktwc.contingency" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                        </div>

                        <div class="" v-if="selectedOpportunity?.ownerInfo?.firstName">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Soft Set</div>
                            <div class="flex space-x-4">
                                <input @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ownerInfo.bestDate" type="date" class="px-3 py-2 border w-full rounded">
                                <input @change="saveOpportunity(selectedOpportunity)" v-model="selectedOpportunity.ownerInfo.bestTime" type="time" class="px-3 py-2 border w-full rounded">
                            </div>
                        </div>

                        <div class="border-2 p-4 text-xs font-mono shadow-lg" v-if="Object.keys(selectedOpportunity.timestamps).some(v => selectedOpportunity.timestamps[v])">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Timestamps</div>
                            <div v-if="selectedOpportunity.timestamps.knock">{{ inRange(selectedOpportunity, 'knock') ? '✅' : '❌' }} <strong>Knocked:</strong> {{ dayjs(selectedOpportunity?.timestamps?.knock).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.timestamps.talk">{{ inRange(selectedOpportunity, 'talk') ? '✅' : '❌' }} <strong>Talked:</strong> {{ dayjs(selectedOpportunity?.timestamps?.talk).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.timestamps.walk">{{ inRange(selectedOpportunity, 'walk') ? '✅' : '❌' }} <strong>Walked:</strong> {{ dayjs(selectedOpportunity?.timestamps?.walk).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.timestamps.contingency">{{ inRange(selectedOpportunity, 'contingency') ? '✅' : '❌' }} <strong>Contingency Logged:</strong> {{ dayjs(selectedOpportunity?.timestamps?.contingency).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.timestamps.softSet">{{ inRange(selectedOpportunity, 'softSet') ? '✅' : '❌' }} <strong>Soft Set Logged:</strong> {{ dayjs(selectedOpportunity?.timestamps?.softSet).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.contractSigned">{{ inRange(selectedOpportunity, 'contractSigned') ? '✅' : '❌' }} <strong>Contract Signed:</strong> {{ dayjs(selectedOpportunity?.contractSigned).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.insuranceAccepted">{{ inRange(selectedOpportunity, 'insuranceAccepted') ? '✅' : '❌' }} <strong>Insurance Accepted:</strong> {{ dayjs(selectedOpportunity?.insuranceAccepted).format('MM/DD/YY h:mma') }}</div>
                            <div v-if="selectedOpportunity.timestamps.revenue">{{ inRange(selectedOpportunity, 'revenue') ? '✅' : '❌' }} <strong>Revenue Changed:</strong> {{ dayjs(selectedOpportunity?.timestamps?.revenue).format('MM/DD/YY h:mma') }}</div>
                        </div>

                        <router-link :to="`/opportunities/${selectedOpportunity.id}/owner-info`" class="bg-gray-100 rounded-lg p-4 text-sm block">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Owner Info</div>
                            <div>
                                <strong>Name:</strong>
                                {{ selectedOpportunity?.ownerInfo?.firstName }} {{ selectedOpportunity?.ownerInfo?.lastName }}
                                <span v-if="selectedOpportunity?.ownerInfo?.goesBy">"{{ selectedOpportunity?.ownerInfo?.goesBy }}"</span>
                            </div>
                            <div><strong>Phone:</strong> {{ selectedOpportunity?.ownerInfo?.phoneNumber }}</div>
                            <div><strong>Text:</strong> {{ selectedOpportunity?.ownerInfo?.callOrText }}</div>
                            <div><strong>Email:</strong> {{ selectedOpportunity?.ownerInfo?.emailAddress }}</div>
                            <div><strong>Best Time:</strong> {{ selectedOpportunity?.ownerInfo?.bestTime }}</div>
                            <div><strong>Best Date:</strong> {{ selectedOpportunity?.ownerInfo?.bestDate }}</div>
                            <div><strong>Notes:</strong> {{ selectedOpportunity?.ownerInfo?.notes }}</div>
                        </router-link>

                        <div class="bg-gray-100 rounded-lg p-4 text-sm" v-if="selectedOpportunity?.insuranceInfo?.insuranceCarrier">
                            <div class="uppercase text-2xs font-semibold opacity-30 tracking-widest mb-2">Insurance Info</div>
                            <div><strong>Insurance Carrier:</strong> {{ selectedOpportunity?.insuranceInfo?.insuranceCarrier }}</div>
                            <div><strong>Policy Number:</strong> {{ selectedOpportunity?.insuranceInfo?.policyNumber }}</div>
                            <div><strong>Claim Number:</strong> {{ selectedOpportunity?.insuranceInfo?.claimNumber }}</div>
                            <div><strong>Adjuster Name:</strong> {{ selectedOpportunity?.insuranceInfo?.adjusterName }}</div>
                            <div><strong>Deductible:</strong> {{ selectedOpportunity?.insuranceInfo?.deductible }}</div>
                            <div><strong>Notes:</strong> {{ selectedOpportunity?.insuranceInfo?.notes }}</div>
                        </div>

                        <div>
                            <div><strong>Damage Locations:</strong> {{ selectedOpportunity.damageObserved?.locations?.join(', ') }}</div>
                            <div><strong>Damaged Items:</strong> {{ selectedOpportunity.damageObserved?.items?.join(', ') }}</div>
                        </div>

                        <div>
                            <div><strong>Objections:</strong> {{ selectedOpportunity.objections?.objections?.join(', ') }}</div>
                            <div><strong>Objection Notes:</strong> {{ selectedOpportunity.objections?.notes }}</div>
                        </div>

                        <div>
                            <div><strong>Damage Type:</strong> {{ selectedOpportunity.damageType?.join(', ') }}</div>
                            <div><strong>Discussion:</strong> {{ selectedOpportunity.discussion }}</div>
                            <div><strong>Inspection:</strong> {{ selectedOpportunity.inspection }}</div>
                            <div><strong>Insurance Accepted:</strong> {{ selectedOpportunity?.insuranceAccepted ? 'Yes' : 'No' }}</div>
                            <div><strong>Contract Signed:</strong> {{ selectedOpportunity?.contractSigned ? 'Yes' : 'No' }}</div>
                            <div><strong>Revenue:</strong> {{ (selectedOpportunity.revenue || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</div>
                            <div><strong>Estimate:</strong> {{ (selectedOpportunity.estimate || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</div>
                        </div>

                    </div>
                    <div class="w-full md:max-w-48 md:ml-4 md:mb-4 md:float-right">
                        <img v-if="selectedOpportunity?.selfie?.url" :src="selectedOpportunity.selfie.url" class="w-full rounded" />
                    </div>
                </div>
            </div>
        </Modal>

        <div class="border-b sticky top-0 bg-white z-40">
            <div class="flex items-center justify-between px-6 h-16">
                <button class="text-blue-500" @click="$router.go(-1)">
                    <i class="fa fa-chevron-left mr-2" />Back
                </button>
                <div class="text-xl">My Opportunities</div>
                <div class="opacity-0 select-none"><i class="fa fa-chevron-left mr-2" />Back</div>
            </div>
            <div class="p-4 pt-0 shadow-lg flex items-center space-x-4">
                <label class="relative flex items-center flex-grow">
                    <input v-model="search" placeholder="Search for a Opportunity" class="w-full border border-gray-300 rounded-full outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600" />
                    <button class="fa fa-times absolute right-2 w-6 h-6 rounded-full bg-gray-100 text-gray-500 dark:bg-gray-700 text-sm" @click="search = ''" v-if="search" />
                </label>
                <button @click="showFilters = true"><i class="fal fa-cog text-xl text-gray-500" /></button>
            </div>
        </div>

        <Modal :active="showFilters" @hide="showFilters = false" class="filters">

            <h2 class="text-2xl text-center">Advanced Filters</h2>
            <button @click="filters = baseFilters; showFilters = false" class="text-red-500 text-center w-full !mt-1 underline">Clear</button>

            <!-- <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Exact Address</div>
                <VagueAddress v-model="filters.address" />
            </div> -->

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Number</div>
                <input v-model="filters.streetNumber" placeholder="Number" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Street</div>
                <input v-model="filters.street" placeholder="Street" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">City</div>
                <input v-model="filters.city" placeholder="City" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Owner Last Name</div>
                <input v-model="filters.owner" placeholder="Last Name" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Revenue</div>
                <div class="flex items-center space-x-4">
                    <input v-model.number="filters.revenueMin" placeholder="Min" />
                    <input v-model.number="filters.revenueMax" placeholder="Max" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Estimate</div>
                <div class="flex items-center space-x-4">
                    <input v-model.number="filters.estimateMin" placeholder="Min" />
                    <input v-model.number="filters.estimateMax" placeholder="Max" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Last Updated Date Range</div>
                <div class="text-xs flex space-x-4 items-center">
                    <input type="date" v-model="filters.rangeStart" class="border border-gray-300 rounded px-2 py-1" />
                    <!-- <span class="mx-2">to</span> -->
                    <input type="date" v-model="filters.rangeEnd" class="border border-gray-300 rounded px-2 py-1" />
                </div>
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Contract Signed</div>
                <Toggle v-model="filters.contractSigned" :small="false" background="bg-gray-100 dark:bg-gray-700" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Insurance Accepted</div>
                <Toggle v-model="filters.insuranceAccepted" :small="false" background="bg-gray-100 dark:bg-gray-700" />
            </div>

            <div class="">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Status</div>
                <p class="text-xs opacity-50 mb-2">Please note the status only contains one value, the highest in the opportunity's lifecycle.</p>
                <select v-model="filters.status">
                    <option :value="null">All Statuses</option>
                    <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
                </select>
            </div>

            <div class="flex justify-center">
                <button @click="showFilters = false" class="bg-red-500 text-white font-semibold px-3 py-2 rounded mx-auto">Apply</button>
            </div>

        </Modal>

        <Mango
            v-if="userId"
            :infinite="true"
            collection="opportunities" :id="null"
            :query="{
                search: {
                    //authorId: userId,
                    or: [{ authorId: userId }, { shotgun: userId }],
                    ...filterSearch
                },
                sort: {updated: -1},
                limit: 250,
                fields: [
                    'address',
                    'ownerInfo',
                    'selfie',
                    'contractSigned',
                    'insuranceAccepted',
                    'timestamps',
                    'revenue',
                    'estimate',
                    'inspection',
                    'discussion',
                    'created',
                    'status'
                ]
            }"
            @update:data="setOpportunities"
            v-slot="{loading}"
            class="w-full"
        >
            <Spinner v-if="!opportunities.length && loading" :small="true" class="w-32 h-32 mt-32 mx-auto" />
            <div v-else-if="!filteredOpportunities.length" class="text-center text-3xl opacity-20 font-bold mt-16">No Opportunities</div>
            <div v-else class="w-full flex flex-col divide-y max-w-[100vw] overflow-hidden">
                <div
                    v-for="opportunity in filteredOpportunities" :key="opportunity.id"
                    class="lg:flex items-center lg:space-x-8 md:hover:bg-gray-50 w-full"
                    :class="{'bg-gray-50': opportunity.status == 'Lockout'}"
                    v-touch:swipe.left="() => proposedDeleteId = opportunity.id"
                    v-touch:swipe.right="() => proposedDeleteId = null"
                    v-touch:tap="() => proposedDeleteId = null"
                    @touchstart="startTouch"
                    @touchmove="moveTouch"
                    @touchend="endTouch"
                >
                    <div class="w-full flex">

                        <div class="w-full shrink-0 p-4 transition-all relative" :class="{'-ml-36': opportunity.id == proposedDeleteId}">
                            <div class="flex items-center space-x-4 md:w-full">
                                <div
                                    @click.stop="selectedPhoto = opportunity.selfie?.url"
                                    class="w-20 h-20 shrink-0 rounded aspect-video bg-center bg-cover bg-gray-600 text-white flex items-center justify-center border-2"
                                    v-lazy:background-image="opportunity.selfie?.url"
                                >
                                    <div v-if="!opportunity.selfie?.url" class="text-2xl font-bold">{{ opportunity.ownerInfo?.firstName?.[0] }}{{ opportunity.ownerInfo?.lastName?.[0] }}</div>
                                </div>
                                <div class="w-full flex-grow" @click="selectOpportunity(opportunity.id)">
                                    <div class="text-2xs text-red-300 font-semibold whitespace-nowrap text-gray-500">{{ dayjs(opportunity.created).format('MMM D, h:mma') }}</div>
                                    <div class="text-xs text-gray-500">
                                        {{ opportunity.ownerInfo?.firstName }} {{ opportunity.ownerInfo?.lastName }}
                                        <span v-if="opportunity.ownerInfo?.goesBy">"{{ opportunity.ownerInfo?.goesBy }}"</span>
                                    </div>
                                    <div class="text-sm font-semibold mt-2">{{ opportunity.address?.address }}</div>
                                    <div class="text-2xs text-gray-500">{{ opportunity.address?.city }}, {{ opportunity.address?.state }} {{ opportunity.address?.zip }}</div>
                                </div>
                                <div class="flex flex-col items-end">
                                    <div
                                        class="text-2xs px-1 py-[2px] bg-gray-100 rounded absolute mb-2 top-3 right-3"
                                        :class="{
                                            'border border-green-500/50 text-green-500 bg-green-50': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(opportunity.status),
                                            'border border-yellow-400/50 text-yellow-500 bg-yellow-50': ['pending','Soft Set'].includes(opportunity.status),
                                            'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'hidden',
                                            'border border-orange-400/50 text-orange-400 bg-orange-50': opportunity.status == 'Retail',
                                            'border border-red-400/50 text-red-400 bg-red-50': ['closed','Lockout'].includes(opportunity.status),
                                            'border border-black/20 text-black bg-black/10': ['Skip','No Answer','Not Interested'].includes(opportunity.status),
                                        }"
                                    >
                                        {{ opportunity.status || 'Skip' }}
                                    </div>
                                    <template v-if="opportunity.status != 'Lockout'">
                                        <router-link v-if="opportunity.status == 'Retail'" :to="`/opportunities/${opportunity.id}/retail`" class="text-blue-500 flex items-center text-sm underline">Edit</router-link>
                                        <router-link v-else :to="`/opportunities/${opportunity.id}/observations`" class="text-blue-500 flex items-center text-sm underline">Edit</router-link>
                                    </template>
                                </div>
                            </div>
                            <div class="flex space-x-4 items-center mt-2">
                                <label class="flex space-x-2 items-center justify-start pt-2">
                                    <div class="text-2xs">Insurance Accepted</div>
                                    <div class="w-full"><Toggle @click.stop :small="true" :modelValue="opportunity.insuranceAccepted" @update:modelValue="opportunity.insuranceAccepted = $event ? new Date() : null; saveOpportunity(opportunity, 'insurance')" /></div>
                                </label>
                                <label class="flex space-x-2 items-center justify-start pt-2">
                                    <div class="text-2xs">Contract Signed</div>
                                    <div class="w-full"><Toggle @click.stop :small="true" :modelValue="opportunity.contractSigned" @update:modelValue="opportunity.contractSigned = $event ? new Date() : null; saveOpportunity(opportunity, 'contract')" /></div>
                                </label>
                                <div class="flex flex-col items-center pt-2">
                                    <input type="number" inputmode="decimal" v-model.number="opportunity.revenue" class="text-2xs px-2 py-1 border w-16" placeholder="revenue" @change="saveOpportunity(opportunity, 'revenue')" />
                                </div>
                                <div class="flex flex-col items-center pt-2">
                                    <input type="number" inputmode="decimal" v-model.number="opportunity.estimate" class="text-2xs px-2 py-1 border w-16" placeholder="estimate" @change="saveOpportunity(opportunity, 'estimate')" />
                                </div>
                            </div>
                        </div>

                            <!-- v-if="proposedDeleteId == opportunity.id"  -->
                        <div
                            @click.stop="deleteOpportunity(opportunity)"
                            class="bg-red-500 text-white px-8 flex items-center justify-center w-36 ml-px font-semibold"
                        >
                            Delete
                        </div>

                    </div>
                </div>
            </div>
        </Mango>

    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Mango from '../../helpers/mango'
import Address from '../layout/address.vue'
import Modal from '../layout/modal.vue'
import Toggle from '../partials/toggle.vue'
import VagueAddress from '../layout/vagueAddress.vue'
import dayjs from 'dayjs'
import latestCutoff from '../../helpers/latestCutoff'

let baseFilters = {
    streetNumber: null,
    street: null,
    city: null,
    owner: null,
    contractSigned: null,
    insuranceAccepted: null,
    revenueMin: null,
    revenueMax: null,
    estimateMin: null,
    estimateMax: null,
    address: {
        formatted: null
    },
    status: null,
    rangeStart: dayjs().subtract(1, 'months').format('YYYY-MM-DD'),
    rangeEnd: dayjs().format('YYYY-MM-DD'),
}

export default {
  components: { Toggle, Modal, Address, VagueAddress },
    inject: ['store'],
    data() {
        return {
            proposedDeleteId: null,
            selectedPhoto: null,
            selectedOpportunityId: null,
            selectedOpportunity: null,
            originalEntry: null,

            opportunities: [],
            showFilters: false,
            savingOpportunity: false,
            search: '',
            baseFilters,
            filters: JSON.parse(JSON.stringify(baseFilters)),

            startX: 0,
            startY: 0,
            isScrollLocked: false,
            latestCutoff: latestCutoff(),

            statusOptions: [
                'Contract Signed',
                'Insurance Accepted',
                'Contingency',
                'Soft Set',
                'Not Interested',
                'Lockout',
                'No Answer',
            ]
        }
    },
    async mounted() {
        let localOpportunities = (await this.store.localDB.getEntries())?.filter(o => !!o.address)
        localOpportunities.forEach(o => {
            if (o.selfie) o.selfie = { url: URL.createObjectURL(o.selfie) }
            if (o.address) o.address.address = `${o.address.number} ${o.address.street}`
        })
        if (localOpportunities?.length) {
            let newOpportunities = localOpportunities.filter(o => !this.opportunities.find(l => l.id == o.id))
            this.opportunities = [...newOpportunities, ...this.opportunities]
        }
    },
    methods: {
        dayjs,
        setOpportunities(opportunities) {
            // for (let o of opportunities) {
            //     if (typeof o.ownerInfo != "object") o.ownerInfo = {}
            //     if (!o.ownerInfo.bestTime) o.ownerInfo.bestTime = null
            //     if (!o.ownerInfo.bestDate) o.ownerInfo.bestDate = null
            // }
            if (this.filterSearch) this.opportunities = opportunities
            else this.opportunities = [...this.opportunities, ...opportunities]
        },
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            return new Date(param) > this.latestCutoff
        },
        async selectOpportunity(id) {
            if (this.proposedDeleteId) return
            this.selectedOpportunity = null
            this.selectedOpportunityId = id
            let o = await Mango.opportunity(id)
            // if (typeof o.ownerInfo != "object") o.ownerInfo = {}
            // if (!o.ownerInfo.bestTime) o.ownerInfo.bestTime = null
            // if (!o.ownerInfo.bestDate) o.ownerInfo.bestDate = null
            if (!o.ktwc) o.ktwc = {}
            if (!o.timestamps) o.timestamps = {}
            this.originalEntry = JSON.parse(JSON.stringify(o))
            this.selectedOpportunity = o
        },
        startTouch(e) {
            // Record the starting touch position
            this.startX = e.touches[0].clientX;
            this.startY = e.touches[0].clientY;
        },
        moveTouch(e) {
            // Lock scrolling here based on real-time movement
            const deltaX = e.touches[0].clientX - this.startX;
            const deltaY = e.touches[0].clientY - this.startY;

            // Check if horizontal movement is greater than vertical movement
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                // If not already locked, lock the scroll
                if (!this.isScrollLocked) {
                    document.body.style.overflowY = 'hidden';
                    this.isScrollLocked = true;
                }
            } else {
                // If already locked, unlock the scroll
                if (this.isScrollLocked) {
                    document.body.style.overflowY = '';
                    this.isScrollLocked = false;
                }
            }
        },
        endTouch(e) {
            // Unlock the scroll on touch end
            document.body.style.overflowY = '';
            this.isScrollLocked = false;
        },
        async deleteOpportunity(opportunity) {
            this.selectedOpportunity = null
            this.selectedOpportunityId = null
            let approved = (await Swal.fire({
                title: 'Are you sure?',
                text: `You will not be able to recover this opportunity! ${opportunity.address?.formatted}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }))?.isConfirmed
            if (approved) {
                let response = await Mango.opportunities.delete(opportunity)
                if (response.deleted) {
                    this.opportunities = this.opportunities.filter(l => l.id != opportunity.id)
                    this.proposedDeleteId = null
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.response,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        },
        parseRevenue(revenueString) {

            if (!isNaN(revenueString)) return revenueString

            // Remove non-numeric characters from the string
            const numericString = revenueString.replace(/[^0-9]/g, '');

            // Parse the numeric string to an integer
            const revenue = parseInt(numericString, 10);

            // Check if it's a valid integer, if not, return 0 or handle the error as needed
            if (!isNaN(revenue)) {
                return revenue;
            } else {
                return 0; // You can choose to return a default value or handle the error differently
            }
        },
        formatRevenue(revenue) {
            // Check if revenue is a valid number
            if (typeof revenue !== 'number' || isNaN(revenue)) {
                return '';
            }

            // Convert the revenue to a string with commas for thousands and a decimal point for cents
            const revenueString = revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

            // Add a dollar sign to the beginning
            return `$${revenueString}`;
        },
        computeStatus(opportunity) {
            if (opportunity?.discussion?.toLowerCase?.() === 'lockout' && opportunity?.addressLockoutApproved) return 'Lockout'
            if (opportunity?.damageType?.includes('retail') || opportunity.retail) return 'Retail'
            if (opportunity?.contractSigned) return 'Contract Signed'
            if (opportunity?.insuranceAccepted) return 'Insurance Accepted'
            if (opportunity?.inspection?.toLowerCase?.() === 'contingency') return 'Contingency'
            if (opportunity?.inspection?.toLowerCase?.() === 'soft set') return 'Soft Set'
            if (opportunity?.discussion?.toLowerCase?.() === 'soft set') return 'Soft Set'
            if (opportunity?.discussion?.toLowerCase?.() === 'not interested') return 'Not Interested'
            if (opportunity?.discussion?.toLowerCase?.() === 'no answer') return 'No Answer'
            return 'Skip'
        },
        async saveOpportunity(opportunity, changed) {
            let { id, insuranceAccepted, contractSigned, revenue, estimate} = opportunity
            if (!isNaN(revenue) && Number(revenue) > 2500000) return Swal.fire({icon: 'warning', title: 'Revenue Limit Reached', text: 'Revenue cannot be greater than $2.5M'})
            this.savingOpportunity = true

            opportunity.status = this.computeStatus(opportunity)

            // Quick Saves - The rest of this function requires data unavailable until the opportunity is selected
            if (['insurance','contract','revenue','estimate'].includes(changed)) {

                if (revenue) revenue = this.parseRevenue(revenue)
                if (estimate) estimate = this.parseRevenue(estimate)
                if (changed == 'revenue') opportunity.timestamps.revenue = new Date()
                if (changed == 'estimate') opportunity.timestamps.estimate = new Date()

                await this.store.localDB.save({id, insuranceAccepted, contractSigned, revenue, estimate, timestamps: opportunity.timestamps})
                return this.savingOpportunity = false

            }

            // if (changed == 'contract') opportunity.timestamps.contingency = contractSigned ? new Date() : null

            console.log('opportunity?.ktwc?.knock', opportunity?.ktwc?.knock, changed)

            // Orignal Entry is only set when the modal is open, it's not set when changing things in the list view
            if (this.originalEntry) {
                if (opportunity?.ktwc?.knock != this.originalEntry?.ktwc?.knock) {
                    opportunity.ktwc.knock = opportunity?.ktwc?.knock ? 1 : 0
                    opportunity.timestamps.knock = opportunity?.ktwc?.knock ? new Date() : null
                }
                if (opportunity?.ktwc?.talk != this.originalEntry?.ktwc?.talk) {
                    opportunity.ktwc.talk = opportunity?.ktwc?.talk ? 1 : 0
                    opportunity.timestamps.talk = opportunity?.ktwc?.talk ? new Date() : null
                }
                if (opportunity?.ktwc?.walk != this.originalEntry?.ktwc?.walk) {
                    opportunity.ktwc.walk = opportunity?.ktwc?.walk ? 1 : 0
                    opportunity.timestamps.walk = opportunity?.ktwc?.walk ? new Date() : null
                }
                if (opportunity?.ktwc?.contingency != this.originalEntry?.ktwc?.contingency) {
                    opportunity.ktwc.contingency = opportunity?.ktwc?.contingency ? 1 : 0
                    opportunity.timestamps.contingency = opportunity?.ktwc?.contingency ? new Date() : null
                }

                let timeAdded = opportunity.ownerInfo?.bestTime && !this.originalEntry?.ownerInfo?.bestTime
                let dateAdded = opportunity.ownerInfo?.bestDate && !this.originalEntry?.ownerInfo?.bestDate
                let alreadySoftSet = (this.originalEntry?.inspection?.toLowerCase?.() == 'soft set' || this.originalEntry?.discussion?.toLowerCase?.() == 'soft set')
                if ((timeAdded || dateAdded) && !alreadySoftSet) {
                    opportunity.timestamps.softSet = new Date()
                    opportunity.inspection = 'Soft Set'
                    opportunity.status = 'Soft Set'
                }
            }

            // if (entryCopy?.inspection?.toLowerCase?.() == 'soft set' && entryCopy?.inspection != this.originalEntry?.inspection) entryCopy.timestamps.softSet = new Date()
            // if (entryCopy?.discussion?.toLowerCase?.() == 'soft set' && entryCopy?.discussion != this.originalEntry?.discussion) entryCopy.timestamps.softSet = new Date()

            let data = {
                id,
                insuranceAccepted,
                contractSigned,
                revenue,
                estimate,
                inspection: opportunity.inspection,
                timestamps: opportunity.timestamps,
                ownerInfo: opportunity.ownerInfo,
                ktwc: opportunity.ktwc,
            }

            // if (opportunity.ownerInfo?.bestDate) data["ownerInfo.bestDate"] = opportunity.ownerInfo?.bestDate
            // if (opportunity.ownerInfo?.bestTime) data["ownerInfo.bestTime"] = opportunity.ownerInfo?.bestTime

            opportunity.status = this.computeStatus(opportunity)

            await this.store.localDB.save(data)

            this.originalEntry = JSON.parse(JSON.stringify(opportunity))
            this.savingOpportunity = false
        }
    },
    computed: {
        userId() {
            return this.store.user?.id
        },
        filteredOpportunities() {
            if (!this.search) return this.opportunities
            return this.opportunities.filter(opportunity => {
                let content = `${opportunity.address?.address} ${opportunity.address?.city} ${opportunity.ownerInfo?.firstName} ${opportunity.ownerInfo?.lastName} ${opportunity.ownerInfo?.goesBy}`.toLowerCase()
                let search = this.search?.toLowerCase()
                return content.includes(search)
            })
        },
        filterSearch() {
            let search = {}
            if (this.filters.address?.id) search['address.id'] = this.filters.address.id
            if (this.filters?.streetNumber) search['address.number'] = this.filters.streetNumber
            if (this.filters?.street) search['address.street'] = {$regex: this.filters.street, $options: 'i'}
            if (this.filters?.city) search['address.city'] = {$regex: this.filters.city, $options: 'i'}
            if (this.filters?.owner) search['ownerInfo.lastName'] = {$regex: this.filters.owner, $options: 'i'}
            if (this.filters?.status) search.status = this.filters.status
            if (this.filters?.contractSigned) search.contractSigned = {$ne: null}
            if (this.filters?.insuranceAccepted) search.insuranceAccepted = {$ne: null}
            if (this.filters?.revenueMin) search.revenue = {$gte: this.filters.revenueMin}
            if (this.filters?.revenueMax) search.revenue = {...(search?.revenue || {}), $lte: this.filters.revenueMax}
            if (this.filters?.estimateMin) search.estimate = {$gte: this.filters.estimateMin}
            if (this.filters?.estimateMax) search.estimate = {...(search?.estimate || {}), $lte: this.filters.estimateMax}
            if (this.filters?.rangeStart) search.compareUpdated = {greaterThan: dayjs(this.filters.rangeStart)}
            if (this.filters?.rangeEnd) search.compareUpdated ? search.compareUpdated.lessThan = dayjs(this.filters.rangeEnd).add(1, 'day') : {lessThan: dayjs(this.filters.rangeEnd).add(1, 'day')}
            return search
        },
    }
}
</script>

<style lang="postcss" scoped>
.filters input,
.filters select {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
