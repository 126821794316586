<template>

    <div class="w-full flex-grow p-12 flex flex-col justify-start items-center">

        <label
            :uploading="uploading"
            :error="error"
            :url="url"
            :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`"
            class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-center mb-8"
        >
            <i class="fas fa-camera mr-2" />
            Take Photo
            <input
                hidden
                type="file"
                accept="image/png, image/jpeg, image/heic"
                id="upload"
                @change="upload"
            />
        </label>

        <div
            v-if="!previewURL"
            class="flex flex-col space-y-4 pb-12 bg-gray-200 w-full max-w-64 aspect-square border-2 border-gray-300 bg-center bg-cover"
            :style="`background-image: url(${previewURL})`"
        />
        <img v-else :src="previewURL" class="w-full max-w-64 border-2 border-gray-300" />

        <btn @click="next" label="Knock" class="mt-8" />

        <div class="text-center mt-8">
            <button @click="next({skip: true})" class="underline text-lg">
                <span>Skip</span>
            </button>
        </div>

		<!-- <button @click="next" :class="`bg-${store.theme.color}-500 border-${store.theme.color}-600`" class="text-xl px-4 py-2 rounded-xl text-white uppercase border-b-4 self-end mt-8">
            {{ store.theme.elementMap.ring }}
        </button> -->

    </div>

</template>

<script>
import Swal from 'sweetalert2';
import Mango from '../../helpers/mango.js'
import Compress from 'compress.js'
const compress = new Compress()
import btn from '../partials/button.vue';

export default {
	props: ['opportunity'],
    inject: ['store'],
    components: { btn },
    data() {
        return {
            savingOpportunity: false,
            uploading: false,
            error: false,
            url: this.opportunity?.selfie?.url || null,
            image: null,
        }
    },
    mounted() {
        compress.attach('#upload', {
            size: 0.75,
            maxWidth: 800,
            quality: .75
        }).then((data) => {

            this.url = null
            this.opportunity.selfie = null
            const img1 = data[0]
            const base64str = img1.data
            const imgExt = img1.ext
            const fileExtension = imgExt?.split?.('/')?.[1] || 'jpg'
            let file = Compress.convertBase64ToFile(base64str, imgExt)
            file = new File([file], `tmp.${fileExtension}`, { type: imgExt });

            this.image = file

            // const formData = new FormData()
            // formData.append('file', file)

            // const request = fetch(`${this.store.api}/upload`, {
            //     method: 'POST',
            //     body: formData,
            // })
            // .then((response) => response.json())
            // .then((json) => {
            //     const path = json.paths[0]
            //     const url = `${this.store.api}${path}`
            //     this.uploading = false
            //     this.url = url
            // })
            // .catch(e => {
            //     this.error = e
            //     this.uploading = false
            // })

        })
    },
    watch: {
        uploading() { this.$emit('uploading', this.uploading) },
        error() { this.$emit('error', this.error) },
        url() { this.$emit('url', this.url) },
        savingOpportunity() {
            this.$emit('saving', this.savingOpportunity)
        }
    },
    computed: {
        previewURL() {
            return this.url || (this.image ? URL.createObjectURL(this.image) : null)
        }
    },
    methods: {
        upload(event) {

            this.image = event.target.files[0]
            // this.uploading = true

        },
		async next({skip = false} = {}) {
            if (this.uploading) return Swal.fire({text: 'Please wait for the image to upload', icon: 'info'})
            this.savingOpportunity = true

            let ktwc = {...this.opportunity.ktwc, knock: skip ? 0 : 1}
            let selfie = this.opportunity?.selfie?.url ? this.opportunity.selfie : this.url || this.image
            let data = { id: this.opportunity.id, selfie, ktwc}
            if (!skip) {
                this.opportunity.timestamps.knock = new Date()
                data.timestamps = this.opportunity.timestamps
            }

            this.opportunity.id = (await this.store.localDB.save(data))?.id
            this.opportunity.selfie = this.url ? {url: this.url} : this.image
            this.savingOpportunity = false
            this.$router.push(skip ? '/' : `/opportunities/${this.opportunity.id}/discuss`)
        }
    }
}
</script>
