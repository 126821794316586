<template>
    <button class="flex items-center py-4 px-6 relative">

        <div v-if="unread" class="w-2 h-2 shrink-0 rounded-full bg-blue-500 absolute left-2" />

        <div class="w-12 h-12 shrink-0 bg-gray-300 bg-gradient-to-t from-gray-400 to-gray-200 rounded-full bg-center bg-cover flex items-center justify-center" :style="group ? '' : `background-image: url(${images[0]})`">
            <div v-if="!group && !images?.length" class="uppercase font-bold text-white text-lg">{{ others?.[0]?.firstName[0] }}{{ others?.[0]?.lastName[0] }}</div>
        </div>

        <div class="xs:hidden md:block ml-4 w-full text-left truncate">
            <h3 class="font-semibold">{{ title }}</h3>
            <p class="text-xs opacity-50 truncate">{{ thread?.preview }}</p>
        </div>
    </button>
</template>

<script>
export default {
    props: ['thread'],
    inject: ['store'],
    computed: {
        group() {
            return this.thread?.members?.length > 2
        },
        others() {
            return this.thread?.members?.filter(m => m.id != this.store.user?.id)
        },
        title() {
            return this.others.map(m => m?.firstName).join(', ')
        },
        images() {
            return this.others?.filter(m => !!m?.image?.url)?.map(m => m?.image?.url)
        },
        unread() {
            return !this.thread?.readBy?.find(m => m?.id == this.store.user?.id)
        }
    }
}
</script>

<style>

</style>
